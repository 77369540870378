.dropdown {
  display: none;
}

@media (max-width: 768px) {
  /* .mobiledropdown {
    display: block;
    margin-right: 10px;
    margin-left: 5px;
    border: none;
    background-color: none;
    background: none;
    font-size: 12px;
  } */

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdownButton {
    background: none;
    border: none;
    font-size: 14px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-left: 5px;
    cursor: pointer;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border: 1px solid #ddd;
  }

  .dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdownContent a:hover {
    background-color: #f1f1f1;
  }

  /* Show the dropdown content when dropdown is open */
  .dropdownButton + .dropdownContent {
    display: block;
    width: 150px;
  }
}
