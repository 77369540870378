.checkout-wraper {
  width: 80%;
  display: flex;
  margin: auto;
}

.checkout-payment-wrapper {
  width: 50%;
  padding: 3rem;
}
.checkout-title {
  font-size: 25px;
  font-weight: 600;
  margin-top: 2rem;
}

.checkout-requireds {
  font-weight: 600;
  font-size: 14px;
}
.chekout-country {
  width: 16rem;
}

.payment-wrapper {
  margin-top: 2rem;
}

.payment-title {
  font-size: 25px;
  font-weight: 600;
}

.checkout-notes {
  font-size: 12px;
  color: #787c80;
}

.payment-details {
  border-bottom: 2px solid #787c80;
  border-left: 2px solid #787c80;
  border-right: 2px solid #787c80;
}

.payment-type-wrapper {
  display: flex;
  gap: 5px;
  padding: 10px;
  align-items: center;
  border-top: 2px solid #787c80;
  justify-content: space-between;
}

.border-bottom-payment {
  border-bottom: 2px solid #787c80;
}

.payment-type-wrapper label {
  margin-bottom: 0px;
}

.frominputs {
  border: black 1px solid;
}

.frominputs:focus {
  border: black 1px solid !important;
}

.width-50 {
  width: 50%;
}

.course-imge {
  height: 20px;
}

.course-img-tit-wrapper {
  gap: 5px;
}

.summarry-payment-wrapper {
  margin-top: 3rem;
}

.summary-total {
  font-size: 16px;
  font-weight: 600;
}

.card {
  width: 42px;
  height: 28;
}

.knet-card {
  width: 30px;
  height: 30px;
}

.HiCreditCard {
  color: grey;
}
