.ant-card-body {
  padding: 10px !important;
  padding-left: 6px !important;
}

.ant-card-meta-title {
  font-size: 1rem !important;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px !important;
}

.ant-card-bordered {
  border: none !important;
}

.ant-card {
  height: 260px;
}

.ant-menu-inline {
  width: 18rem !important;
}

.ant-menu-inline .ant-menu-inline {
  width: calc(18rem - 4px) !important;
}

.ant-menu-inline-collapsed {
  width: 70px !important;
}

/* .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px 16px !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px 16px !important;
} */

.ant-menu-item {
  padding: 0px 22px !important;
}

.ant-menu-submenu-title {
  padding: 0px 22px !important;
}

.ant-color {
  color: white !important;
}

.ant-color-default {
  color: rgba(0, 0, 0, 0.65);
}

.ant-notify {
  position: relative;
  right: -60%;
  top: 0.1px;
}

.ant-notify-toggle {
  right: 150%;
  top: -0.5em;
}
