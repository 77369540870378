.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav-chat {
  height: calc(86vh - 62px);
  width: 100%;
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 10px;
}

.sidenav .menu {
  padding: 4px 4px 4px 20px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  display: block;
  transition: 0.3s;
}

.sidenav .menu:hover {
  background-color: #f1f1f1;
}

.sidenav-chat .closebtn {
  position: absolute;
  top: 50px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav .menu {
    font-size: 18px;
  }
}
