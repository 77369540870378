.admin-navbar {
  display: flex;
  border-bottom: 2px solid #f1f1f1;
}

.admin-navbar-menu {
  padding: 10px;
  border-left: 1px solid #f1f1f1;
}

.dashbored {
  padding: 10px;
}

.satatistics-card {
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', 'tnum';
  position: relative;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  height: 250px;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 2px rgba(0, 0, 0, 0.09);
}
