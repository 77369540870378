.menu-header-story-model {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
}

.header-wrapper-each {
  cursor: pointer;
  padding: 5px;
}

.header-wrapper-each-selected {
  background-color: #dbdbdb;
}

.header-wrapper-each:hover {
  background: #dbdbdb;
}

.header-each-menu-image-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.story-title {
  border: none !important;
  outline: none;
  width: 100%;
}

.story-desc-title {
  border-left: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  outline: none;
  width: 100%;
  border-radius: 10px;
}

.story-desc-title:hover {
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.form-control-focus:hover {
  border: 1px solid #000;
}

.story-desc-title:focus {
  /* border-left: 1px solid #3f51b5;
  border-top: 1px solid #3f51b5;
  border-bottom: 1px solid #3f51b5; */
  border: 1px solid #80bdff;
}

.story-desc-title:focus-visible {
  /* border-left: 1px solid #3f51b5;
  border-top: 1px solid #3f51b5;
  border-bottom: 1px solid #3f51b5; */
  border: 1px solid #80bdff;
}

.story-title :focus {
  border: none !important;
}

.story-title :focus-visible {
  border: none !important;
}
.text-box {
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.text-box:hover {
  border: 1px solid #000;
}

.text-box-view {
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.text-box:focus-within {
  border: 1px solid #3f51b5;
}
.text-area-desc {
  resize: none;
}

video {
  height: unset;
}

.video-warapper-ratio-adujestment {
  position: relative;
  padding-bottom: 56.25%;
}

.video-whereplayer-shows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.thumbnail-box {
  border: 1px solid #dbdbdb;
}
.container-box-model {
  max-width: 960px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.row-model {
  display: flex;
  flex-wrap: wrap;
}

.uploadCover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.thumbnail-img-border {
  border: 1px dashed #9b9b9b;
}

.thumbnail-img-selected-border {
  border: 2px solid yellow;
}

.audio-selector {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 5px;
  padding: 10px;
}
.fixed-wrapper {
  position: absolute;
  top: 0;
  background-color: red;
}

.footer-upload {
  border-top: 1px solid #dbdbdb;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-edit-image {
  padding: 0px 5px 5px 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 880;
  position: absolute;
  width: 150px;
  right: 0px;
}

.category-tags {
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  font-size: 0.9rem;
  word-break: break-word;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.category-tags:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 4px;
}

.category-tags:hover .category-tags-cancel-box {
  color: #0d0d0d;
  display: block;
}
.category-tags-cancel-box {
  color: #737070;
  margin-bottom: 0.2rem;
  display: none;
}

.category-tags-view {
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  font-size: 0.9rem;
  word-break: break-word;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 6px;
}
.tags {
  justify-content: center;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  font-size: 0.9rem;
  word-break: break-word;
}
.tags-cancel-box {
  color: #737070;
  margin-bottom: 0.2rem;
}
.tags-cancel-box:hover {
  color: #0d0d0d;
}
.tags:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.tag-length-count-box {
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.language-select-border {
  border-bottom: solid 1px #118acf;
}

.image-select-type {
  padding: 15px;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.container-box-model {
  height: calc(100% - 5rem);
}
.row-model {
  max-height: calc(100% - 6.5rem);
  overflow: auto;
}
.footer-upload {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.popup-form-area {
  margin-top: 1rem;
  position: relative;
}
.popup-lbl {
  position: absolute;
  background: #fff;
  padding: 0 8px;
  left: 15px;
  top: -11px;
  height: 22px;
}
.popup-form-area .form-control {
  height: 46px;
}
.tag-box {
  height: 200px;
}

.storie-edit-section {
  position: relative;
}
.storie-edit-dropdown-content {
  right: 0 !important;
  top: 4px !important;
}
