.staf-warapper-ratio-adujestment {
  position: relative;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
}

.staf-whereplayer-shows {
  position: absolute;
  top: 0;
  height: 100%;
}

.add-attchment-tab {
  float: right;
}

.attachment-tabs {
  display: flex;
  justify-content: space-between;
}

.staf-img-category {
  object-fit: cover;
  width: 40%;
  float: left;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .staf-img-category {
    width: 100%;
  }

  .staf-margin {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .staf-img-category {
    width: 100%;
  }
}
