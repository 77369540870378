body {
  background-color: white;
}

.signup-area {
  padding: 88px 0;
  min-height: calc(100vh - 91px);
}

.registration-area {
  border-radius: 8px;
  border: 1px solid #dadce0;
  width: 100%;
  margin: 0 auto;
  height: 400px;
  background-color: white;
}

.registration-model-container {
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  padding: 20px;
  background: #fff;
  font-family: sans-serif;
  width: 100%;
  margin: 0 auto;
}

.registration-modal-header {
  min-height: 16.428571429px;
  padding: 15px;
  border-bottom: 1px solid #ffffff00;
}

.registration-modal-title {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  text-align: center !important;
  color: #3b3b3b;
}

.registration-from-control-border {
  border: none;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
}

.registration-form-control {
  font-family: 'Muli', FontAwesome, sans-serif;
  border: none;
  background: #fff;
  padding: 5px 10px;
  box-shadow: none;
}

.registration-form-control:focus {
  box-shadow: none;
  outline: 0 none;
}

.input-group-addon {
  position: relative;
  top: 6px;
}

.register-select {
  border: none;
  border-bottom: 2px solid white;
  width: 80%;
}

.registration-input-90 {
  width: 90%;
}

.registration-otp_text {
  margin-top: 35px !important;
  text-align: left;
  font-weight: 600;
}

.bacground-color {
  background-color: red;
}
