.course-wrapper {
  width: 70%;
  margin: auto;
}

.card-box-course {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
}

.titile-card-course {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 2.2rem;
  font-weight: 700;
  max-height: 4.4rem;
  color: black;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.play-icon-sub-wrapper {
  background-color: white;
  position: absolute;
  top: 40%;
  left: 45%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb-wrapper {
  position: relative;
}

.play-icon-wrapper {
  position: absolute;
  top: 0;
  background: black;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}
.play-icon {
  margin-left: 2px;
  color: black;
}

.play-bar {
  display: none;
}

.card-box-course:hover .play-bar {
  display: block;
}
