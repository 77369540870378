.rec-item-wrapper {
  width: auto !important;
  margin-left: 10px;
}

.list-category-carousel {
  padding: 0 1px 2px 0px;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  background: rgb(242, 242, 242);
  font-weight: 505 !important;
  border: 1px solid #c7c7c7;
}

.rec-pagination {
  display: none !important;
}

.rec-arrow {
  border: none !important;
  border-radius: 0 !important;
  background-color: white !important;
  box-shadow: none !important;
}

.rec-item-wrapper {
  margin-left: 0px !important;
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .list-category-carousel {
    margin: 0 5px;
  }
  .rec-carousel-item-0 span.list-category-carousel {
    padding-left: 0;
    margin-left: 0;
  }

  .carousel-box {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid #dbdbd9;
    padding-bottom: 4px;
  }
}
