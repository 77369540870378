.profile_header_area {
  height: 40px;
  color: black;
  /* background-color: #5682a3; */
  background-color: white;
  /* border-bottom: solid 1px #dbdbdb; */
  border-bottom: 1px solid rgb(184, 184, 184);
}

.border-bottom {
  float: left;
  border-bottom: solid 3px white;
  height: 37px;
  padding-right: 0px;
}

.profile-image {
  width: 20px;
  border-radius: 50% !important;
  border: solid 2px white;
  margin: 12px 10px;
  height: 20px;
}

.profileNavBarList {
  padding: 2px 10px 0px 10px;
  line-height: 37px;
  cursor: pointer;
  margin: auto;
  color: black;
}

.dropdown-content-menu {
  right: 28px;
  display: none;
  position: absolute;
  background-color: #fff;
  color: black;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content {
  right: 28px;
  display: none;
  position: absolute;
  background-color: #fff;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.drop-down-navbar-menu {
  text-align: left;
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}
.sub-menu-drop-down-navbar {
  padding-left: 20px;
}

.drop-down-navbar-menu:hover {
  background-color: #f1f1f1;
}

.profileNavBarList .dropdown-content-menu {
  width: 20rem;
  right: 5px !important;
}
.editprofile-section {
  padding: 1rem;
  margin: 0;
  border-radius: 0;
  background: #f7f7f7;
}
.editprofile-section .sub-menu-drop-down-navbar {
  padding-left: 0;
  margin-bottom: 1rem;
  position: relative;
}
.editprofile-section .sub-menu-drop-down-navbar span {
  display: block;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 5px;
  background: #f7f7f7;
  padding: 2px 10px;
  line-height: 20px;
  font-size: 0.7rem;
  color: #aaa;
}
.editprofile-section .registration-from-control-border {
  border: 1px solid #ebe9e9;
  height: 44px;
  padding: 9px 12px 3px 12px;
  /* background: transparent; */
}
