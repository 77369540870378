.margin-top-1 {
  margin-top: 10px;
}

.forgot-password-cointainer {
  margin-top: 100px;
}

.forgot-form-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.forgot-box {
  display: flex;
  justify-content: center;
}

.input-label {
  font-size: 16px;
  font-weight: 600;
}

.input-email {
  width: 500px;
  margin-top: 10px;
  padding: 5px;
}

.button-wrapper {
  margin-top: 10px;
}

.button-reset-send {
  background: #21aedd !important;
  width: 100%;
}
.or-login {
  margin-left: 5px;
  color: #087ba1;
  cursor: pointer;
}

.success-message-box {
  background-color: aquamarine;
  color: black;
  width: 400px;
  padding: 25px;
  font-weight: 600;
}

.loader-spiner-additional {
  height: 20px !important;
  width: 20px !important;
}
