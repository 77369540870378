.profile-area {
  overflow: auto;
  border-right: solid 1px #dbdbdb;
}

.profilemenu-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  white-space: nowrap;
}
.profilemenulink {
  white-space: nowrap;
}

.profilemenu-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0;
}
.profilemenu-scroll::-webkit-scrollbar-track {
  background: none;
}
.profilemenu-scroll::-webkit-scrollbar-thumb {
  background: none;
}
.profilemenu-scroll::-webkit-scrollbar-thumb:hover {
  background: none;
}

.profile-image-div {
  float: left;
  margin-top: 2px;
  margin-left: 4px;
}

.user-image {
  height: 80px;
  border-radius: 50% !important;
  border: solid 4px #ddd;
  margin: 0px;
  width: 80px;
}

.user-porfile-image {
  height: 130px;
  width: 130px;
  border-radius: 50% !important;
  border: solid 2px white;
  margin: 0px;
}

.user-profile-section {
  width: 100%;
  position: relative;
}
.ups-bg {
  width: 100%;
}
.ups-bg-img {
  width: 100%;
}
.ups-bg-upload-btn {
  background: #ddd;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  right: 9rem;
  bottom: 4rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ups-profile-upload-btn {
  background: #ddd;
  border-radius: 50%;
  width: 2.5rem;
  height: 3rem;
  position: absolute;
  top: 45%;
  left: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ups-size-camera-upload {
  font-size: 2.5rem;
}

.ups-profile {
  display: flex;
  margin: -4rem 0 0 4rem;
  position: relative;
}
.ups-profile-area {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}
.ups-profile-img {
  width: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
}
.ups-profile-text {
  white-space: nowrap;
  margin: 4.5rem 0 0 1rem;
}
.ups-name {
  font-weight: 600;
  font-size: 1.3rem;
  width: 100%;
  white-space: nowrap;
}
.ups-subscribe {
  width: 100%;
  display: flex;
}
.ups-subscribe-count {
  margin-right: 0.5rem;
}
.ups-subscribe,
.ups-subscribe-section {
  margin-top: 0.75rem;
}
.ups-subscribe-section {
  width: 100%;
  display: flex;
}
.ups-subscribe-area {
  display: flex;
}
.ups-subscribe-lbl {
  color: #dc3545;
}

.btn-subscribe2 {
  color: #000;
  border-bottom: 1px solid #000;
  background: none;
  font-size: 1rem;
  width: 95px;
}

.ups-share-icon {
  height: 20px;
  margin-right: 1rem;
}
.ups-lang {
  color: #5e5e5e;
  padding: 5px 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.ups-lang.active {
  border-bottom: 2px solid #4d4341;
  color: #000;
}
.ups-language-section {
  position: absolute;
  right: 1rem;
  margin-top: 6rem;
}
.ups-language-area {
  display: flex;
}
.ups-language-selection-btn {
  display: none;
  position: relative;
  margin-right: 1.5rem;
  background: #ddd;
  border-radius: 0.25rem;
  font-weight: 700;
}

.width-bio-profiles {
  width: 45%;
}

.profile-name-title {
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
}

.new-profile-name-title {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 5px;
  color: #555;
  background-color: transparent;
  border-radius: 4px !important;
  margin-top: 5px;
  font-weight: 500;
  font-size: 1.3rem;
}

.proifile-category {
  display: flex;
  flex-wrap: wrap;
  color: #858585;
  font-size: 16px;
}

.profile-breif {
  font-size: 15px;
}

.bio-video-width {
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .width-bio-profiles {
    width: 100%;
  }
  .bio-video-width {
    width: 100%;
  }
  .profile-breif {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media (max-width: 375.98px) {
  .ups-profile {
    margin: -2rem 0 0 1rem;
  }
  .ups-profile-area {
    width: 4rem;
    height: 4rem;
  }
  .ups-profile-text {
    margin: 2rem 0 0 1rem;
  }
  .ups-bg-upload-btn {
    right: 5rem;
    bottom: -1rem;
  }

  .ups-profile-upload-btn {
    height: 1rem;
    width: 1rem;
    top: 40%;
    left: 2rem;
  }

  .ups-size-camera-upload {
    font-size: 1rem;
  }

  .ups-name {
    font-size: 0.8rem;
  }
  .ups-subscribe,
  .ups-subscribe-section {
    margin-top: 0.1rem;
  }
  .ups-language-section {
    margin-top: 3rem;
    display: block;
    right: 0;
  }
  .ups-language-area {
    display: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    margin-top: 4px;
    border-radius: 0.25rem;
  }
  .ups-language-area.active {
    display: block;
  }
  .ups-lang.active {
    border: 0;
  }
  .ups-lang:hover {
    background: #edf4ff;
  }
  .ups-language-selection-btn {
    display: block;
    font-size: 0.8rem;
  }
  .ups-language-selection-btn:after {
    top: 6px;
  }
}

@media (min-width: 376px) and (max-width: 575.98px) {
  .ups-profile {
    margin: -1.5rem 0 0 1rem;
  }
  .ups-profile-area {
    width: 3rem;
    height: 3rem;
  }
  .ups-profile-text {
    margin: 2.5rem 0 0 1rem;
  }
  .ups-bg-upload-btn {
    right: 6rem;
    bottom: 1rem;
  }

  .ups-language-section {
    right: 2rem;
  }

  .ups-subscribe-section {
    margin: 0.5rem 0 1rem -3.5rem;
  }
  .ups-profile-text {
    margin: 1.5rem 0 0 1rem;
  }

  #bio_area {
    margin: 0 !important;
  }

  .ups-profile-upload-btn {
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    left: 2rem;
  }

  .ups-size-camera-upload {
    font-size: 1.5rem;
  }
  .ups-subscribe,
  .ups-subscribe-section {
    margin-top: 0.3rem;
  }
  .ups-language-section {
    margin-top: 3.5rem;
    display: block;
    right: 0;
  }
  .ups-language-area {
    display: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    margin-top: 4px;
    border-radius: 0.25rem;
  }
  .ups-language-area.active {
    display: block;
  }
  .ups-lang.active {
    border: 0;
  }
  .ups-lang:hover {
    background: #edf4ff;
  }
  .ups-language-selection-btn {
    display: block;
    font-size: 0.9rem;
  }
  .ups-language-selection-btn:after {
    top: 8px;
  }
  .ups-profile-area {
    width: 4rem;
    height: 4rem;
  }
  .ups-profile {
    margin: -1.5rem 0 0 0.75rem;
  }
  .ups-language-section {
    right: 2rem;
  }
  .ups-subscribe-section {
    margin: 0.5rem 0 1rem -3.5rem;
  }
  .ups-profile-text {
    margin: 1.5rem 0 0 1rem;
    width: 100%;
  }
  #bio_area {
    margin: 0 !important;
  }
  .ups-name {
    margin-left: 0rem;
    width: calc(100% - 4.75rem);
    text-align: center;
  }
  .ups-subscribe {
    text-align: center;
    margin: 0.3rem 0 0 -5rem;
    width: calc(100% - 4.75rem);
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .profile-section {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .profile-section .container {
    padding: 0;
  }
  .profile-area {
    overflow: hidden;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ups-profile {
    margin: -3.5rem 0 0 2rem;
  }
  .ups-profile-area {
    width: 6rem;
    height: 6rem;
  }
  .ups-profile-text {
    margin: 3.5rem 0 0 1rem;
  }
  .ups-bg-upload-btn {
    right: 7rem;
    bottom: 1.5rem;
  }

  .ups-profile-upload-btn {
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    left: 2rem;
  }

  .ups-size-camera-upload {
    font-size: 1.5rem;
  }
  .ups-subscribe,
  .ups-subscribe-section {
    margin-top: 0.1rem;
  }
  .ups-language-section {
    margin-top: 4.5rem;
    display: block;
    right: 0;
  }
  .ups-language-area {
    display: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    margin-top: 4px;
    border-radius: 0.25rem;
  }
  .ups-language-area.active {
    display: block;
  }
  .ups-lang.active {
    border: 0;
  }
  .ups-lang:hover {
    background: #edf4ff;
  }
  .ups-language-selection-btn {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .ups-profile {
    margin: -3.5rem 0 0 2rem;
  }
  .ups-profile-area {
    width: 6rem;
    height: 6rem;
  }
  .ups-profile-text {
    margin: 3.5rem 0 0 1rem;
  }
  .ups-bg-upload-btn {
    right: 7rem;
    bottom: 1.5rem;
  }

  .ups-profile-upload-btn {
    height: 2rem;
    width: 2rem;
    top: 45%;
    left: 3rem;
  }
  .ups-size-camera-upload {
    font-size: 2rem;
  }
  .ups-subscribe,
  .ups-subscribe-section {
    margin-top: 0.1rem;
  }
  .ups-language-section {
    margin-top: 4.5rem;
  }
}

.profile-basic-details-div {
  float: left;
  margin: 5px;
}

.follow-following-tab {
  width: 230px;
  margin-left: 10px;
  margin-top: 5px;
}

.follow-tab {
  font-size: 14px;
  line-height: 15px;
}

.user-name {
  margin-left: 10px;
  font-size: 20px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.user-name-user-profile {
  font-size: 20px;
  padding-bottom: 5px;
}

.group-tab {
  float: left;
  height: 30px;
  padding: 5px 10px;
  font-size: 13px;
  border-bottom: 1px solid rgb(17, 138, 207);
}

.profile-bio-tab {
  float: left;
  height: 30px;
  padding: 5px 10px;
  font-size: 13px;
}

.profile-bio-tab-2 {
  float: left;
  padding: 10px 15px;
  font-size: 13px;
  position: relative;
  bottom: -1px;
  color: #5e5e5e;
  font-weight: 600;
}

.profile-bio-tab-sub {
  float: left;
  padding: 10px 8px;
  font-size: 13px;
  margin-top: 3px;
  position: relative;
  bottom: -2px;
  color: #5e5e5e;
  font-weight: 600;
}

.language-select-border {
  border-bottom: solid 1px #118acf;
}

.language-menu-select {
  border-bottom: solid 1px #4d4341;
  color: black;
}

.bio-ddl {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  padding: 2px 2px 2px 0px;
  color: #555;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-right: none;
  border-left: none;
}

.sub-group-icon {
  float: left;
  margin: 5px;
}
.sub-group-name {
  margin-left: 10px;
  padding: 7px;
  line-height: 22px;
  margin-bottom: 2%;
}

.sub-group-add-new {
  float: left;
  margin: 5px;
  margin-top: 10px;
  padding-top: 11px;
  padding: 7px;
}
.sub-group-icon-img {
  height: 50px;
  width: 50px;
  border-radius: 50% !important;
  border: solid 1px #ddd;
}

.sub-short-intro {
  border-bottom: 1px solid rgb(219, 219, 219);
  padding-bottom: 5px;
}

.sub-group-profile {
  height: 200px;
  width: 200px;
  border-radius: 50% !important;
  border: solid 1px #ddd;
}

.sub-toggle-button {
  text-align: right;
  height: 80px;
}
.sub-group-info-border {
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 4px;
  padding: 12px;
}

.sub-group-thumbnail {
  margin: "auto";
  width: 100%;
}

.sub-group-small-profile {
  height: 25px;
  width: 25px;
  border-radius: 50% !important;
  border: solid 1px #ddd;
}

.sub-group-small-name {
  line-height: 25px;
  margin-left: 10px;
}

.sub-group-details {
  float: left;
  width: 32%;
  padding: 15px 5px 0px 25px;
  line-height: 22px;
}

.sub-group-view-description {
  height: 120px;
  width: 350px;
  border: solid 1px black;
}
.message-box {
  width: 406px;
  background-color: white;
  margin: 30px 10px 10px 10px;
  padding: 12px 12px 8px 12px;
  border: 1px solid #a6c9e2;
  background: #fcfdfd;
  color: #222;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.message-box-profile {
  height: 25px;
  width: 25px;
  border-radius: 50% !important;
  border: solid 1px #ddd;
}

.iframe-video {
  width: "100%";
  height: "250px";
}

.story-margin {
  padding: 5px;
  margin: 5px;
}

.story-box {
  overflow: auto;
}
.prfile-name {
  display: flex;
  justify-content: space-between;
  width: 20%;
}

.profile-image-porifle-section {
  display: flex;
  justify-content: center;
}

.user-follow-wrrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}

.user-follow-wrrapper div {
  -ms-flex: 1; /* IE 10 */
  flex: 1;
}

.countries-list {
  border-right: 2px solid #eeeeee;
  height: 100vh;
}

.user-follower-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.user-sub-name {
  display: flex;
  align-items: center;
  height: 40px;
}

.user-follow-wrapper {
  font-weight: 700;
}
@media screen and (max-width: 576px) {
  .story-box {
    height: 100% !important;
  }

  .text-center-mobile {
    text-align: center !important;
  }
  .profile-image-porifle-section {
    display: flex;
    justify-content: unset;
  }
  .sub-group-profile {
    height: 100px;
    width: 100px;
    border-radius: 50% !important;
    border: solid 1px #ddd;
  }
}

.message-box-border {
  border-top: 1px solid rgb(219, 219, 219);
  display: block;
  position: fixed;
  bottom: 0px;
  width: 73%;
  background: white;
}
.message-box-width {
  height: 50px;
  line-height: 50px;
  width: calc(100% - 110px);
}

.story-model-media {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 5px #0d0022;
  padding: 0px 5px;
  background: rgb(252, 253, 253) none repeat scroll 0% 0%;
  border: 1px solid rgb(166, 201, 226);
  display: none;
  padding-bottom: 3px;
  margin-left: 10px;
  position: fixed;
}

.message-width-area {
  width: 97%;
  height: 40px;
  border: none;
  outline: none;
}

.language-bio {
  padding: 6px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 10;
  text-align: left;
  margin-bottom: 5px;
}

.menu-box-bio {
  padding: 0px 5px 5px 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 880;
  position: relative;
  width: 150px;
  top: -19px;
  float: right;
}

.menu-box-general {
  padding: 0px 5px 5px 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 880;
  position: absolute;
  width: 150px;
  top: 0px;
  float: right;
  right: 0px;
}

.menu-box-sub {
  padding: 0px 5px 5px 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 880;
  position: relative;
  width: 150px;
  top: 6px;
  float: right;
  left: 10px;
}

.menu-line {
  font-size: 14px;
  line-height: 20px;
  padding: 3px 5px 3px 5px;
}

.menu-header {
  border-bottom: 1px solid #ccc;
  line-height: 20px;
  padding: 3px 5px 3px 5px;
  text-align: right;
}

.edit_area_header {
  border-bottom: 1px solid #ccc;
  line-height: 30px;
  padding: 3px 5px 3px 5px;
}

.save_button {
  padding: 10px 5px 4px 5px;
  background-color: transparent;
  font-weight: bold;
  color: #0b69d8;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 2px solid #0b69d8;
}

.for_edit_field {
  width: 95%;
  height: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 2px;
  color: #555;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px !important;
  margin-bottom: 4px;
  margin-top: 5px;
}

.add-sub-group-body {
  padding: 10px;
  left: 400px;
  top: 125px;
}

.add-file-section-sub-group {
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 20px;
}

.add-image-sub-group {
  float: left;
  margin: 20px 60px 10px 10px;
  width: 170px;
}

.image-sub-group {
  height: 140px;
  width: 140px;
  text-align: center;
}

.image-add-menu-bar {
  margin: 10px 0px 0px 0px;
  cursor: pointer;
}

.add-intro-video-sub-group {
  float: left;
  margin: 20px 60px 10px 10px;
  text-align: center;
}

.video-image-sub-group {
  height: 140px;
  width: 200px;
  text-align: center;
}
.progress-bar {
  height: 100%;
}
.profile-name-title {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 5px;
  color: #555;
  background-color: transparent;
  border-radius: 4px !important;
  margin-top: 5px;
}

.profile-bio-height {
  height: 75%;
}

.profile-bio-height-if-category {
  height: 45%;
}

.bio-consultant {
  border-radius: 10px;
}

/* Font size of 11px for .profile-bio-tab-2 on screens up to 1280px */
@media (max-width: 1279px) {
  .profile-bio-tab-2 {
    font-size: 9px;
  }
}

/* Font size of 11px for .profile-bio-tab-2 on screens between 1280px and 1440px */
@media (min-width: 1280px) and (max-width: 1440px) {
  .profile-bio-tab-2 {
    font-size: 9px;
  }
}

/* Font size of 13px for .profile-bio-tab-2 on screens wider than 1440px */
@media (min-width: 1441px) {
  .profile-bio-tab-2 {
    font-size: 13px;
  }
}

@media screen and (max-width: 576px) {
  .profile-bio-tab-2 {
    font-size: 0.6rem;
    padding: 0px 15px;
  }
  .profile-bio-tab-sub {
    font-size: 0.5rem;
  }
  .profile-bg-section {
    padding: 0;
  }

  .profile-tab-section {
    padding: 0;
  }
  .menu-bio-mobile {
    margin: 10px !important;
  }
  .bio-description-mobile {
    padding: 0 1rem;
  }
  .bio-consultant {
    border-radius: 0;
  }
}
