/* global */

/*for scrolling*/
*::-webkit--scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide the arrow/spinner in WebKit browsers (Chrome, Safari) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrow/spinner in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Start Mozilla Fire Fox Scroll Style */
* {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.profilemenu-scroll {
  scrollbar-color: #fff #fff;
}
/* !End Mozilla Fire Fox Scroll Style */

.push-left {
  float: left;
}

/* html {
  overflow: hidden;
} */
body {
  color: black !important;
}

.push-right {
  float: right;
}

.clearfix {
  clear: both;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.height-video-story {
  height: 500px;
}

.border-bottom-gray01 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555759;
  background-color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.margin-battom-15 {
  margin-bottom: 15px;
}

.input-ht {
  min-height: 40px;
}

.icon-size {
  font-size: 20px;
}

.border-bottom-white {
  border-bottom: 1px solid #eeeeee;
}

.border-bottom-white2 {
  border-bottom: 2px solid #eeeeee;
}

.color-primary {
  color: #12a3d6;
}

.pl-70p {
  padding-left: 85px;
}

.width-100 {
  width: 100%;
}
.width-5 {
  width: 5%;
}

.for-user-understand {
  font-size: 10px;
  color: #787c80;
}
.gap-5p {
  gap: 5px;
}
.text-bold {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-Roboto-16 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
}

.flex-gap-3r {
  gap: 3rem;
}
.display-flex {
  display: flex;
}

.display-flex-align-center {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: end;
}
.flex-space-btw {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.header-h1 {
  text-align: center;
  color: white;
}
.alert-imp {
  font-weight: 500;
  color: #f95000;
  text-decoration: none;
}

.margin-5 {
  margin: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}
.margin-left-2 {
  margin-left: 2px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.font-bold {
  font-weight: bold;
}

.model-content-default {
  border-radius: 4px;
  width: 500px;
  background: white;
  padding: 10px;
}

.fbt {
  border: 2px solid #e9e9e9;
  background: #e9e9e9 none repeat scroll 0 0;
  min-height: 40px;
  text-align: center;
  width: 40%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  color: #f94f00;
  font-weight: 600;
  border-radius: 4px;
}

.fbt:hover {
  border-bottom: 2px solid #f94f00;
  background: #ffffff none repeat scroll 0 0;
}

.btn-proceed {
  background-color: #1a73e8;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  width: 88px;
  height: 36px;
  cursor: pointer;
}

.btn-proceed:hover {
  background: #1b66c8;
}

.btn-previous {
  color: #1a73e8;
  border: none;
  background-color: transparent;
  width: 88px;
  height: 36px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-previous:hover {
  color: #1b66c8;
  background-color: #e6f1fc;
}

.ul-common {
  list-style-type: none;
  overflow: hidden;
  width: 100%;
  padding: 12px 0 12px 5px;
  margin-bottom: 0px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  font-size: 15px;
  list-style-type: none;
}

.progress-bar {
  width: 100%;
  background-color: #ddd;
  overflow: hidden;
  height: 5px;
  border-radius: 10px;
}

.load-bar {
  background-color: #4caf50;
  color: #4caf50;
}

.danger-red {
  color: red;
}

.success-green {
  color: #28a745;
}

.edit-profile-wrapper {
  width: 10rem;
}

.item {
  width: 70%;
  padding: 12px 0 12px 5px;
  margin-bottom: 0px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  font-size: 15px;
  list-style-type: none;
  margin-left: 10px;
}

.item-lang-list {
  padding: 0px 0px 0px 5px;
  margin-bottom: 0px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  list-style-type: none;
}

.lang-list-wrapper {
  margin-left: 30px;
  border-left: solid 1px #ddd;
  width: 80%;
}
.hover-item {
  display: none;
}
.lang-list-hover:hover .hover-item {
  display: block;
}

.focus-none:focus {
  box-shadow: 0 0 0 0;
  border: none;
}

.nav-chat-backgroud-color {
  background: "#f0f2f5";
}
.ck-editor__editable {
  height: 200px;
}

.small-font-color-sections-course {
  color: #6a6f73;
  font-size: 16px;
  font-weight: 400;
}

.screen-manage-width-profile {
  width: 6%;
  flex: 0 0 6%;
}

.screen-manage-width-home {
  width: 6.8%;
  flex: 0 0 6.8%;
}

.vertical-navbar-width-adjust-box {
  flex: 0 0 16%;
  max-width: 16%;
}

.vertical-navbar-width-adjust {
  position: fixed;
  z-index: 500;
  width: 16%;
}
.vertical-navbar-adjacent-div-width-adjust {
  flex: 0 0 84%;
  max-width: 84%;
}

.vertical-navbar-width-adjust-box-close {
  flex: 0 0 4.6%;
  max-width: 4.6%;
}

.vertical-navbar-width-adjust-close {
  position: fixed;
  z-index: 500;
  width: 4.6%;
}
.vertical-navbar-adjacent-div-width-adjust-close {
  flex: 0 0 95.4%;
  max-width: 95.4%;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0 !important;
}

.ant-card {
  height: 270px !important;
}

.tooltip {
  position: relative;
  display: inline-block !important;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.loading {
  display: inline-block;
  font-size: 14px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

.backgroud-color-theme-gray {
  background: #d1d7dc;
}

.video-warapper-16-9-center {
  height: 0;
  padding-top: 56.25%;
  background-color: #f6f6f6;
  position: relative;
}

.video-16-9-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-o {
  padding: 0;
}

.prl-o {
  padding-left: 0px;
  padding-right: 0px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.form-div-border-bottom {
  padding: 6px 12px;
  height: 34px;
  border-bottom: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.video-whereplayer-shows-mobile-full-width {
  width: 100%;
  height: 100%;
}

.eRmJbc {
  box-sizing: border-box !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  font-size: 1.6em !important;
  background-color: none !important;
  color: #999 !important;
  box-shadow: none;
  border-radius: none !important;
  border: none !important;
  padding: 0 !important;
  width: 0px !important;
  height: 0px !important;
  min-width: 0px !important;
  line-height: 0px !important;
  -webkit-align-self: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important;
  cursor: pointer !important;
  outline: none !important;
}
.khvUfi {
  box-sizing: border-box !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  font-size: 1.6em !important;
  background-color: none !important;
  color: #999 !important;
  box-shadow: none;
  border-radius: none !important;
  border: none !important;
  padding: 0 !important;
  width: 0px !important;
  height: 0px !important;
  min-width: 0px !important;
  line-height: 0px !important;
  -webkit-align-self: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important;
  cursor: pointer !important;
  outline: none !important;
}

.buPiXl:hover:enabled,
.buPiXl:focus:enabled {
  color: #000000 !important;
}
.buNJCv:disabled {
  opacity: 0 !important;
}
.buPiXl.rec-arrow-right {
  position: relative;
  box-shadow: -15px -3px 26px 15px #fff !important;
}

.buPiXl.rec-arrow-left {
  position: relative;
  box-shadow: 15px 3px 26px 15px #ffffff !important;
  z-index: 1;
}

.cturYU {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.tooltip {
  z-index: 1070;
  display: block;
  margin: 0;
  margin-left: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 100 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
  font-size: 15px;
}
.padding-right-card {
  padding-right: 0px;
}

.description-box-general {
  overflow: hidden;
  text-overflow: " ...";
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 70px;
}

.vjs-tech {
  height: 100% !important;
  background: #0f0f0f;
}

.plyr__video-wrapper video {
  height: 100% !important;
  background: #f6f6f6;
}

.blink_me {
  animation: blinker 10s linear infinite;
}

.show-catgories:hover {
  background-color: #ddd;
  padding: 2px;
  cursor: pointer;
}

.chapter-tabs {
  color: #6a6f73;
  border-bottom: none;
}

.chapter-tabs-selected {
  color: black;
  border-bottom: 1px solid black;
}

.mobile-icon-search {
  display: none;
}

.select-bg-color {
  background-color: rgb(209, 215, 220);
}

.text-title-expert {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: black;
  white-space: wrap;
}

.expert-text-container {
  height: 100%;
  overflow: hidden;
}

.text-truncate-verticly {
  display: -webkit-box;
  -webkit-line-clamp: 12; /* Adjust if needed */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 100%;
}

.display-flex-block {
  display: flex;
}

.tologin {
  margin: 15px;
  margin-top: 60px;
  font-size: 25px;
}

.marginTopStores {
  margin-top: 60px;
}
@media (max-width: 1600px) {
  .text-truncate-verticly {
    -webkit-line-clamp: 9; /* Adjust based on your design */
  }
}

@media (max-width: 1200px) {
  .text-truncate-verticly {
    -webkit-line-clamp: 7; /* Adjust based on your design */
  }
}

@media (max-width: 992px) {
  .text-truncate-verticly {
    -webkit-line-clamp: 6; /* Adjust based on your design */
  }
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .text-truncate-verticly {
    -webkit-line-clamp: 6; /* Adjust based on your design */
  }
}

/* Adjust for extra-small screens */
@media (max-width: 576px) {
  .text-truncate-verticly {
    -webkit-line-clamp: 5; /* Adjust based on your design */
  }
  .marginTopStores {
    margin-top: 10px;
  }
}

@keyframes blinker {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
}

/* Medium devices (tablets, 768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .text-title-expert {
    font-size: 14px;
  }
}

@media (min-width: 320px) and (max-width: 1399.98px) {
  .buNJCv,
  .buPiXl {
    opacity: 0 !important;
  }
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.loader-spiner {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #1aa9da;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  height: 100vh;
}
.mobile-story-card-details {
  display: none;
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .buNJCv,
  .buPiXl {
    display: none;
  }
  .cturYU {
    margin: 0;
  }
  .rec-carousel-item-0 span.list-category-carousel {
    padding-left: 0;
    margin-left: 0;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .vertical-navbar-width-adjust-close,
  .vertical-navbar-width-adjust-box-close {
    flex: 0 0 9%;
    max-width: 9%;
  }
}
@media only screen and (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-block-flex {
    display: flex !important;
  }
  .nav-chat-backgroud-color {
    background: "#f0f2f5";
  }
}

@media only screen and (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .nav-chat-backgroud-color {
    background: "#f0f2f5";
  }
}

@media screen and (max-width: 576px) {
  .nav-chat-backgroud-color {
    background: #008069 !important;
  }

  .display-flex-block {
    display: block;
  }

  .mobile-story-card-details {
    display: inline;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .no-margin-mobile-left {
    margin-left: -15px !important;
  }
  .no-margin-mobile-right {
    margin-right: -15px !important;
  }
  .margin-top-10-mobile {
    margin-top: 10px;
  }
  .margin-mobile-left {
    margin-left: -25px;
  }
  .margin-mobile-right {
    margin-right: -25px;
  }
  .no-height-mobile {
    height: auto !important;
  }
  .padding-right-mobile {
    padding-right: 15px !important;
  }
  .khvUfi {
    display: none;
  }

  .eRmJbc {
    display: none;
  }
  .vertical-navbar-adjacent-div-width-adjust-close {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .padding-right-mobile-card {
    padding-right: 5px;
    padding-left: 5px;
  }

  .story-card-wrapper {
    width: 100%; /* Full width of the parent container (mobile screen width) */
    max-height: 100vw; /* Max height equal to the viewport width to make it square */
    border: 1px solid #ccc; /* Optional: adds a border around the box */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .story-image-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }

  .story-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire container */
  }

  .story-description-card {
    padding: 10px 10px 0px 10px; /* Center-align the text */
    font-size: 16px;
    flex-grow: 1; /* Allows the description to expand if needed */
    max-height: calc(100vw - 56.25vw);
    overflow: hidden;
  }
  .story-description-card .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* Number of lines before truncation */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(100% - 56.25%);
  }
  .story-description-card .title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .story-description-card .category {
    font-size: 14px;
    color: #888; /* Optional: change category text color */
    margin: 5px 0;
  }

  /* .video-js.vjs-fluid {
    height: 90vh !important;
  } */
}

/* test css */
.value_section {
  min-height: 25px;
  padding: 4px 4px;
}

.block_section {
  padding-left: 20px;
  margin-left: 12px !important;
  margin-bottom: 4px;
}

.block_border {
  border-left: solid 1px ButtonFace;
}

.complete_border {
  border: solid 1px gray;
}

/* chat styles */

.chat-img-box {
  position: relative;
}

.chat-img-time {
  position: absolute;
  right: 6px;
  bottom: 6px;
  color: white;
}

.category-heading-home-page-course {
  font-size: 1.2rem;
  font-weight: 600;
}

.text-black {
  color: #000;
}

.cover-no-warapper-ratio-adujestment {
  display: flex;
  justify-content: center;
}

.navbar-home-normal,
.navbar-home-reel {
  display: none;
}

.agreement-text {
  font-size: 1.4rem;
}

@media only screen and (min-width: 576px) {
  /* Adjust min-width as needed for tablet devices */
  .navbar-home-normal {
    display: block;
  }

  .agreement-text {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 576px) {
  .navbar-home-reel {
    display: block;
    color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
  }

  .mobile-card {
    max-height: calc(100vh - 155px);
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0px !important;
  }
  .mobile-card .card-box {
    max-height: 100%;
    padding-right: 0;
    padding-left: 0;
    border-radius: 0 !important;
    box-shadow: 0 0 !important;
  }
  .mobile-card .mobile-card-anchor {
    height: 80%;
    display: block;
    min-height: 375px;
    max-height: 680px;
  }
  .image-warapper-ratio-adujestment {
    height: 100%;
    padding: 0;
    border-radius: 0 !important;
  }

  .play-video-home-page {
    position: absolute;
    display: flex;
    height: 70% !important;
    align-items: center;
  }
  .image-whereplayer-shows {
    position: unset !important;
    object-fit: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
  .mobile-card-cover {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: 1;
    background: #fff;
    width: 100%;
    padding: 0 0.25rem;
  }

  .mobile-card-desc {
    position: absolute;
    background: #fff;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: 100px;
  }

  .coming-soon {
    font-size: 28px;
  }

  .agreement-text {
    font-size: 1rem;
  }
}

.selected-tab-background-course {
  background-color: #d1d7dc;
}

@media (max-width: 576px) {
  .navbar-header > span.d-sm-inline,
  .top-menu-icon {
    display: none !important;
  }

  .mobile-icon-search {
    display: inline-block;
  }

  .container .p-3.serach-result,
  .container .p-3.serach-result > .container {
    padding: 0 !important;
  }
}

/* @media (max-width: 767.98px) {
  .mobile-icon-search {
    display: block;
  }
} */

@media (min-width: 1200px) {
  .mobile-card {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1408px) {
  .ant-menu-vertical-container .mobile-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1576px) {
  .mobile-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1734px) {
  .ant-menu-vertical-container .mobile-card {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1902px) {
  .mobile-card {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 2060px) {
  .ant-menu-vertical-container .mobile-card {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
}
@media (min-width: 2228px) {
  .mobile-card {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
}
