.home-tab {
  background: #eee;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);
  padding: 10px;
}

.category-list {
  border-bottom: 1px solid #eee;
  background: #fff;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
}
.home-content-tab {
  height: 80vh;
  background-color: white;
}

.scroll-content {
  margin-top: 5px;
  overflow: auto;
  height: calc(100vh - 168px);
}

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 20px !important;
  line-height: 24px;
  border-radius: 5px;
}

.home-course-card-wrapper {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  justify-content: center;
  position: relative;
}
.home-course-card-wrapper a {
  width: 33%;
}

.home-courses-pr-card {
  display: flex;
}

.home-courses-pr-card-img-wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  border: 1px solid #d1d7dc;
  cursor: pointer;
}

.img-course-wrapper-relative {
  position: relative;
}
.title-learn {
  font-size: 2rem;
  margin-left: 6%;
}
.home-img-course {
  height: 150px;
  width: 120px;
  object-fit: cover;
}
.home-courses-title {
  font-weight: 500;
  font-family: SuisseWorks, Georgia, Times, 'Times New Roman', serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  color: black;
}

.home-courses-pr-card-img-wrapper:hover {
  background-color: #f7f9fa;
}
.play-icon-wrapper-course {
  position: absolute;
  top: 0;
  background: black;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}
.play-icon-course {
  margin-left: 2px;
  color: black;
}

.play-icon-sub-wrapper-course {
  background-color: white;
  position: absolute;
  top: 40%;
  left: 35%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-training-link {
  position: absolute;
  right: 8%;
  top: -10%;
  font-size: 16px;
  border-bottom: 1px solid #51aeff;
}
.my-course-title {
  position: absolute;
  left: 8%;
  top: -10%;
  font-size: 16px;
  border-bottom: 1px solid #51aeff;
}

.my-training-link-mobile {
  display: none;
}

@media (max-width: 576px) {
  .home-course-card-wrapper {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
    justify-content: unset;
    width: 100%;
    flex-wrap: unset !important;
  }
  .home-course-card-wrapper a {
    width: 280px !important;
  }
  .home-courses-pr-card {
    display: flex;
    width: 280px !important;
  }

  .home-course-card-wrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .home-course-card-wrapper::-webkit-scrollbar-track {
    background: none;
  }
  .home-course-card-wrapper::-webkit-scrollbar-thumb {
    background: none;
  }
  .home-course-card-wrapper::-webkit-scrollbar-thumb:hover {
    background: none;
  }

  .home-course-card-wrapper {
    scrollbar-color: none;
    scrollbar-width: none;
  }

  .my-training-link {
    display: none;
  }

  .my-training-link-mobile {
    display: block;
    margin-top: 5px;
    text-decoration: underline;
    color: #1890ff;
  }

  .title-learn {
    font-size: 1.5rem;
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .home-course-card-wrapper {
    flex-wrap: wrap;
  }
  .home-course-card-wrapper a,
  .home-courses-pr-card,
  .home-courses-pr-card,
  .home-courses-pr-card-img-wrapper {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .play-icon-sub-wrapper-course {
    left: 28%;
  }

  .home-img-course {
    width: 90px;
  }

  .title-learn {
    font-size: 1.5rem;
    margin-left: 0;
  }

  .my-training-link {
    top: -20%;
  }
}
