:root {
  --color-white: #fff;
  --time-animation: 0.6s;
}

@keyframes pulse {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* html {
  scrollbar-width: none;
  background: #000;
} */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden scroll;
  -ms-overflow-style: none;
  background: #000;
}

/* body::-webkit-scrollbar {
  display: none;
} */

.layout {
  width: 100%;
  height: calc(100vh * 3);
  position: relative;
}

.layout .section-stick {
  margin: auto;

  position: fixed;
  right: 50px;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  justify-content: flex-start;

  z-index: 1000;
}

.layout .section-stick .stick,
.layout .section-stick .active {
  width: 13px;
  height: 62px;

  border: none;
  border-radius: 9px;
}

.layout .section-stick .stick {
  position: relative;

  background-color: rgba(255, 255, 255, 0.4);
  transition: all var(--time-animation) ease-in-out;
}

.layout .section-stick .active {
  position: absolute;

  background-color: var(--color-white);
  box-shadow: 0px 0px 12px 1px var(--color-white);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.card-box-section > a {
  height: 100%;
  display: block;
}
.layout section {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: var(--color-white);
  font-size: 80px;
  font-weight: 600;

  transform: translateY(100vh);
  transition: all var(--time-animation) ease-in-out;

  z-index: 0;
}

.layout section .home {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  align-items: center;
  height: 100%;
}
.layout section .home > div {
  height: 100%;
}
.layout section .home .card-box-section {
  height: 100%;
  position: relative;
}
.image-thumb-section {
  display: block;
  height: 100%;
  text-decoration: none !important;
  color: #fff;
  width: 50px;
}
.description-thumb-section {
  display: block;
  height: 100%;
  text-decoration: none !important;
  color: #fff;
  font-size: 1rem;
  line-height: 36px;
  text-align: left;
}
.image-thumb {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  float: left;
  margin-left: 0.5rem;
}
.thumb-section {
  display: flex;
  align-items: center;
  width: 100%;
}
.thumb-section-left {
  display: flex;
  align-items: center;
  height: 40px;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 10px);
}
.thumb-section-right {
  width: 60px;
  position: absolute;
  bottom: 60px;
  right: 0;
}
.thumb-section-right i {
  font-size: 1.5rem;
}
.countsection {
  font-size: 1rem;
}
.thumb-longdesc {
  text-decoration: none !important;
  position: absolute;
  bottom: 60px;
  left: 0;
  font-size: 0.85rem;
  color: #fff !important;
  width: calc(100% - 2rem);
  text-align: left;
  padding: 0 1rem;
  height: 150px !important;
  font-weight: 400;
}
.image-warap-ratio-adujestment {
  height: 100%;
  background: #000;
}
.btn-subscribe {
  position: absolute;
  right: 0;
  border-radius: 25px;
  padding: 0;
  color: #fff;
  border: 1px solid #fff;
  background: none;
  font-size: 1rem;
  width: 95px;
}

.more-content {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 1.5rem;
  margin-top: -24px;
}
.description-view-story {
  position: absolute;
  bottom: 0;
}

.description-view-story {
  overflow: hidden;
  text-overflow: ' ...';
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.65);
}

.description-view-story.active {
  height: 100%;
  display: block;
  overflow: unset;
  text-overflow: unset;
}
video {
  height: 100%;
}
/* .layout section .home svg {
  animation: pulse 1.4s linear infinite normal;
} */

.layout .s1 {
  background-color: #000;
  transform: translateY(0);
  z-index: 4;
}

.layout .s2 {
  background-color: #000;
  z-index: 3;
}

.layout .s3 {
  background-color: #000;
  z-index: 2;
}

.layout .s4 {
  background-color: #000;
  z-index: 1;
}

#author {
  margin: auto;
  position: fixed;
  bottom: 40px;
  left: 40px;

  display: flex;
  column-gap: 10px;
  align-items: center;

  color: var(--color-white);
  font-size: 25px;
  z-index: 10;
}

#author svg {
  font-size: 40px;
}

/************************************* RESPONSIVE ***************************************/

/* MOBILE */
@media screen and (max-width: 576px) {
  /* body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    -webkit-appearance: none;
  } */

  .layout .section-stick {
    right: 20px;
    bottom: 20px;
    transform: scale(0.7);
  }

  .layout section {
    font-size: 40px;
  }

  #author {
    left: 20px;
    bottom: 20px;
    font-size: 18px;
  }

  #author svg {
    font-size: 30px;
  }
}
.stick {
  display: none;
}
