.bg-model {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.model-content {
  border-radius: 4px;
  width: 600px;
  position: relative;
}

.video-close {
  padding: 5px;
  color: red;
  margin-right: -8px;
  cursor: pointer;
  position: absolute;
  top: -8px;
  right: -30px;
}

video {
  height: 337px;
}
