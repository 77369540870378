.dp-img-size {
  margin: 0 auto;
  clear: both;
  border: 3px solid white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.bio-consultant {
  border-radius: 10px;
}

.story-backgroud-color {
  border-radius: 5px;
  background: whitesmoke;
}

@media (max-width: 576px) {
  .bio-consultant {
    border-radius: 0;
  }

  .story-backgroud-color {
    border-radius: 5px;
    background: whitesmoke;
  }

  .play-video-home-page {
    display: none !important;
  }

  .story1-card-box {
    padding: 2px;
  }
  .course-card-box-section,
  .course-card-box {
    padding: 0 !important;
  }
  .story-card-box-profile {
    padding: 0 1re !important;
  }
}
