.card-box {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
}

.course-cover {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.card-profile-course {
  display: flex;
}
.course-cover-box {
  padding: 0px;
  float: none;
}
.titile-card {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 0.85rem;
  line-height: 2.2rem;
  max-height: 4.4rem;
  color: black;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.card-box:hover {
  -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.description-box {
  overflow: hidden;
  text-overflow: " ...";
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.65);
}
.description-box-with-no-image {
  overflow: hidden;
  text-overflow: " ...";
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.65);
}

.image-warapper-ratio-adujestment {
  position: relative;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
}

.image-warapper-ratio-adujestment-user-story {
  position: relative;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
}

.play-video-home-page {
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
}

.image-whereplayer-shows {
  position: absolute;
  top: 0;
  height: 100%;
}

.image-whereplayer-shows-user-story {
  position: absolute;
  top: 0;
  height: 100%;
}

.story-backgroud-color {
  border-radius: 5px;
  background: whitesmoke;
}

@media (max-width: 575px) {
  .card-box {
    padding-top: 1px !important;
  }
  .course-card-box-section,
  .course-card-box {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .card-profile-course {
    display: none;
  }

  .story-backgroud-color {
    border-radius: 5px;
    background: whitesmoke;
  }

  .story1-card-box {
    padding: 2px;
  }

  .play-video-home-page {
    display: none;
  }

  .course-cover-box {
    padding: 10px;
    float: left;
    width: 50%;
  }
  .story-card-box-profile,
  .story-card-box-section .description-box {
    padding: 0 0rem;
  }
}
