:root {
  /* --main-color: #e6f7ff; */
  --main-color: #527da3;
  --second-color: #1890ff;
  --chat-body-color: #fff;
  --border-color: #c9dfe9;
  --third-color: rgb(82 125 163 / 20%);
  --chat-open-color: #527da3;
}

.chat-box-wrapper {
  background-color: #c1cada;
  height: calc(100vh - 40px);
  /* padding-top: 60px; */
  font-size: 1rem;
}

.chat-section {
  margin: auto;
  width: 80%;
  height: 100%;
  align-items: center;
}

.chat-box {
  width: 100%;
  /* max-width: 350px; */
  background: #fff;
  height: 86vh;
  /* margin: 5rem auto; */
  overflow: auto;
}

.chat-box-left-border {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.chat-box-right-border {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.chat-top-section {
  display: flex;
  padding: 0.5rem;
  height: 60px;
  align-items: center;
  color: #454443;
  border-top-left-radius: 12px;
  position: relative;
  justify-content: space-between;
}
.chat-title {
  width: calc(100% - 60px);
  font-weight: 700;
  font-size: 1.3rem;
  color: #fff;
}
.chat-camera,
.chat-search,
.chat-more {
  width: 24px;
  padding: 0 0.9rem;
  cursor: pointer;
  text-align: center;
}
.chat-more {
  padding: 0;
}
.chat-box-body {
  background: var(--chat-body-color);
  height: calc(100% - 75px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-box-profile-section {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0 0.5rem;
  align-items: center;
  width: 100%;
  min-width: 300px;
}
.chat-remarks-username {
  font-weight: 700;
}

.chat-box-profile-img {
  height: 45px;
  width: 45px;
  border-radius: 25px;
  margin: -10px 0.5rem 0 0;
}
.chat-box-profile-img-nav {
  height: 45px;
  width: 45px;
  border-radius: 25px;
  margin: 0px 0.5rem 0 0;
}
.chat-user-section {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 70px);
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding-bottom: 10px;
}
.chat-user-msg {
  font-size: 0.85rem;
  position: relative;
  color: #757575;
}
/* .chat-user-msg:before {
  position: absolute;
  content: "✔";
  left: 0;
  color: #757575;
} */
.chat-notopen .chat-user-msg:before {
  color: #757575;
}
.chat-open .chat-user-msg:before {
  color: var(--chat-open-color);
}
.chat-get .chat-user-msg {
  padding-left: 0;
}
.chat-get .chat-user-msg:before {
  content: unset;
}
.chat-user-time-section {
  font-size: 0.75rem;
  color: #757575;
  display: flex;
  width: 60px;
  flex-wrap: wrap;
}
.chat-get-notopen .chat-user-time-section {
  color: var(--chat-open-color);
}
.chat-user-time {
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
}
.chat-user-notify {
  text-align: right;
  margin: 2px 0 0 40px;
  background: #ff4d4f;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: white;
}
.chat-get-notopen .chat-user-notify {
  background: var(--chat-open-color);
  color: #fff;
}
.chat-user-img {
  width: 10px;
  height: 10px;
}

.chat-box-body::-webkit-scrollbar,
.chat-page-body::-webkit-scrollbar,
.chat-remarks::-webkit-scrollbar {
  width: 5px;
}
.chat-box-body::-webkit-scrollbar-track,
.chat-page-body::-webkit-scrollbar-track,
.chat-remarks::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.chat-box-body::-webkit-scrollbar-thumb,
.chat-page-body::-webkit-scrollbar-thumb,
.chat-remarks::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.chat-box-body::-webkit-scrollbar-thumb:hover,
.chat-page-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-box-profile-svg-back {
  height: 25px;
  align-self: center;
  padding-right: 10px;
  cursor: pointer;
}

.chat-box-profile-svg-menu {
  height: 25px;
  align-self: center;
  cursor: pointer;
}

.chat-page {
  display: none;
}

.chat-menu-border {
  border-bottom: 2px solid #eff3f8;
}

.chat-nav {
  display: flex;
  padding: 0.5rem;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 12px;
  color: #454443;
}
.chat-nav-left {
  display: flex;
  align-items: center;
}
.chat-nav-right {
  display: flex;
}

.chat-nav-position-relative {
  position: relative;
}
.menubox-chat-nav {
  background-color: white;
  position: absolute;
  left: 0px;
  top: 40px;
  font-size: 10px;
  display: none;
}
.chat-page-body {
  background: #fbfbfb;
  height: calc(100% - 113px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}
.chat-page-msg-section {
  /* height: 76px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fbfbfb;
  border-bottom-right-radius: 12px;
  position: sticky;
  bottom: 0;
}
.chat-msg-area {
  height: 45px;
  background: #fff;
  border-radius: 25px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  width: 100%;
}
.chat-type-area {
  width: 100%;
}
.chat-msg-area img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
}
.chat-attchement-area {
  display: flex;
  height: 100%;
  align-items: center;
  width: 60px;
  cursor: pointer;
}
.chat-type-input {
  height: 100%;
  width: 100%;
  border: 0;
  resize: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0px 5px;
  outline: 0 !important;
  scrollbar-width: none;
}
.chat-type-input::-webkit-scrollbar {
  display: none;
}

.chat-voice-area {
  background: #e6f7ff;
  width: 45px;
  height: 45px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  cursor: pointer;
}
.chat-clip-art {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.voice-recorder {
  width: 25px;
  height: 25px;
}
.chat-sent-msg {
  text-align: right;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.chat-sent-time {
  text-align: right;
  font-size: 0.75rem;
  margin-top: 5px;
  padding: 0 15px 0 50px;
  position: relative;
}
.chat-sent-time:after {
  content: "✔";
  position: absolute;
  right: 0;
  bottom: 0;
}
.chat-sent-msg-open .chat-sent-time:after {
  color: var(--chat-open-color);
}
.chat-sent-text {
  padding: 0.25rem 0.5rem;
  background: #dce8da;
  border-radius: 5px;
  display: inline-block;
  text-align: left;
}
.chat-received-msg {
  text-align: left;
  margin: 0.5rem 5rem 0.5rem 0.5rem;
}
.chat-received-time {
  text-align: right;
  font-size: 0.75rem;
  margin-top: 5px;
  padding: 0 15px 0 50px;
  position: relative;
}
.chat-received-time:after {
  content: "✔";
  position: absolute;
  right: 0;
  bottom: 0;
}

.chat-received-text {
  padding: 0.25rem 0.5rem;
  background: #fff;
  border-radius: 5px;
  display: inline-block;
  text-align: left;
}

.chat-recived-translate-text {
  font-size: 0.7rem;
  color: #8b8c8c;
}
.chat-sent-img-section,
.chat-sent-voice-section,
.chat-sent-video-section,
.chat-sent-file-section {
  background: #dce8da;
  padding: 0.25rem;
  border-radius: 6px;
  text-align: left;
  position: relative;
  margin-left: 45%;
}

.player-button-video {
  position: absolute;
  left: 50%;
  top: 40%;
  cursor: pointer;
  height: 20px;
}
.chat-sent-img {
  width: 100%;
  border-radius: 6px;
}
.chat-received-img-section,
.chat-received-voice-section,
.chat-received-video-section,
.chat-received-file-section {
  background: #fff;
  padding: 0.25rem;
  border-radius: 6px;
  margin-right: 45%;
  position: relative;
}
.chat-received-img {
  width: 100%;
  border-radius: 6px;
}
.chat-voice-msg,
.chat-video-msg {
  width: 100%;
}
.chat-unread-seaction {
  background: #ebe8e8;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-unread {
  background: #fff;
  color: #555;
  border-radius: 25px;
  padding: 0.25rem 0.5rem;
}
.chat-day-section {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-chat-day {
  background: #ddd;
  color: #555;
  border-radius: 7px;
  padding: 0.25rem 0.5rem;
}
.chat-pdf-img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
}

.chat-pdf-image-file {
  height: 60px;
  margin-right: 0.5rem;
}
.pdf-file {
  text-decoration: none !important;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: space-between;
  color: inherit;
}
.pdf-file:hover {
  color: black;
}
.chat-pdf-details {
  width: 100%;
  display: flex;
}
.chat-pdf-display-img {
  width: 100%;
  height: 100px;
}

.chat-search-area {
  width: 85%;
}
.chat-search-input {
  width: 100%;
  border-radius: 12px;
  border: 0;
  font-size: 1rem;
  padding: 0.5rem;
  outline: 0 !important;
  height: 35px;
  border: 2px solid#f5f7fb;
  margin: 5px;
}

.chat-text-chat-box {
  overflow: hidden;
  text-overflow: " ...";
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-user {
  padding-bottom: 3px;
}

.chat-remarks-profile-img-nav {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  margin: 0px 0.5rem 0 0;
}
.chat-remarks-profile-box {
  padding: 0.5rem;
  display: flex;
  font-size: 12px;
}
.remarks-text {
  margin: 0.5rem;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}
.remark-alert-time {
  padding: 0.5rem;
  border-bottom: 1px solid black;
}

.chat-attchement-main-section {
  position: relative;
}
.chat-attchement-section {
  position: absolute;
  bottom: 24px;
  z-index: 1;
  right: -16px;
}
.hidden {
  display: none !important;
}
.chat-attchement-section.active {
  display: block;
}
.chat-attchement-ul-section {
  padding: 0;
}
.chat-attchement-li-section {
  list-style: none;
  margin: 8px;
}

.chat-attchement-icon {
  width: 53px;
  height: 53px;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(11, 20, 26, 0.17), 0 6px 8px rgba(11, 20, 26, 0.29);
  position: relative;
  background: none;
  color: #fff;
  cursor: pointer;
}

.chat-attch-image:before {
  background: #ac44cf;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-right-radius: 27px;
  border-top-left-radius: 27px;
  z-index: -1;
}
.chat-attch-image:after {
  background: #bf59cf;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-right-radius: 27px;
  border-bottom-left-radius: 27px;
  z-index: -1;
}
.chat-attch-audio:before {
  background: #d3396d;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-right-radius: 27px;
  border-top-left-radius: 27px;
  z-index: -1;
}
.chat-attch-audio:after {
  background: #ec407a;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-right-radius: 27px;
  border-bottom-left-radius: 27px;
  z-index: -1;
}
.chat-attch-file:before {
  background: #5157ae;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-right-radius: 27px;
  border-top-left-radius: 27px;
  z-index: -1;
}
.chat-attch-file:after {
  background: #5f66cd;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-right-radius: 27px;
  border-bottom-left-radius: 27px;
  z-index: -1;
}
.chat-attch-map:before {
  background: #059186;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-right-radius: 27px;
  border-top-left-radius: 27px;
  z-index: -1;
}
.chat-attch-map:after {
  background: #02a698;
  content: "";
  height: 27px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-right-radius: 27px;
  border-bottom-left-radius: 27px;
  z-index: -1;
}
.chat-attchement-icon img {
  margin: 0;
}
.chat-attch-text {
  display: none;
  margin-top: 10px;
  font-size: 0.85rem;
}

.container-loader {
  height: 34px;
  width: 34px;
  background-color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 8px;
  box-shadow: 20px 20px 40px rgba(60, 60, 150, 0.25);
  display: grid;
  place-items: center;
}
.circular-progress {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #ffffff;
  border-radius: 50%;
}
.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  color: #231c3d;
  opacity: 0;
}
.payment-cointainer {
  width: 50%;
  background-color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  margin: 1rem;
  font-size: 18px;
  border: 2px solid #8a8a8a;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.payment-dim-text {
  color: gray;
}

.payment-pay-buttun {
  width: 100%;
  margin: 10px;
  border: 1px solid #d4d4d4;
  background-color: #eaeaea;
  border-radius: 10px;
}

.payment-button-effect:hover {
  background-color: #d4d4d4;
}

.payment-rejact-buttun {
  width: 35%;
}

.loader-spiner-addtionl {
  width: 15px;
  height: 15px;
}

.paid-notification {
  background: #ff4d4f;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: white;
  font-size: 12px;
  margin-top: 10px;
}

.model-bg {
  padding: 1rem;
}
.model-bg-inside {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
}
.model-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}
.model-title {
  margin: 0;
}
.model-form-control {
  height: 40px;
}
.model-btn {
  height: 40px;
  width: 100%;
}

.chat-initailizer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 767.98px) {
  .chat-page,
  .chat-remarks {
    display: block;
  }
  .caht-main {
    margin: auto;
    display: flex;
    max-width: 710px;
  }
  .chat-box-profile-svg-back {
    display: none;
  }
  .chat-box,
  .chat-left-area {
    display: block !important;
  }
  .chat-attchement-li-section {
    position: relative;
  }
  .chat-attchement-icon:hover ~ .chat-attch-text {
    display: block;
    position: absolute;
    right: -105px;
    top: 4px;
    background: #000;
    padding: 5px;
    color: #fff;
    border-radius: 25px;
    width: 85px;
    text-align: center;
  }
  .chat-attchement-main-section {
    position: absolute;
    left: 1rem;
  }
  .chat-msg-area {
    margin-left: 3.5rem;
    width: calc(100% - 135px);
  }
  .chat-clip-art {
    display: none;
  }
  .chat-attchement-area {
    width: unset;
  }
}

@media (max-width: 767.98px) {
  .chat-attchement-section {
    bottom: 42px;
    width: 90vw;
    min-height: 100px;
    background: #fff;
    right: -75px;
    border-radius: 12px;
    padding: 10px 30px;
  }
  .chat-attchement-ul-section {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .chat-attchement-li-section {
    margin-right: 39px;
    text-align: center;
  }
  .chat-attchement-li-section:nth-child(3),
  .chat-attchement-li-section:nth-child(6),
  .chat-attchement-li-section:nth-child(9),
  .chat-attchement-li-section:nth-child(12) {
    margin-right: 8px;
  }
  .chat-attch-text {
    display: block;
  }
  .chat-attchement-area {
    margin-left: 10px;
  }

  .chat-box-display {
    display: none;
  }
  .chat-box-left-border,
  .chat-box-right-border {
    border-radius: 0;
  }
  .chat-section {
    margin: 0 -15px;
    width: unset;
    height: 100%;
    align-items: center;
  }
  .chat-left-area {
    height: 100%;
    padding: 0px !important;
  }

  .chat-center-area {
    display: none;
    height: 100%;
    padding: 0px !important;
  }

  .chat-box {
    height: 100% !important;
  }

  .chat-right-area {
    display: none;
    height: 100%;
    padding: 0px !important;
  }

  .chat-page-body {
    height: calc(100% - 163px);
  }

  .chat-right-area.active {
    display: block;
  }
  .chat-box-wrapper {
    height: 100vh;
  }

  .chat-page-msg-section {
    border-radius: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .chat-box-remarks-svg-back {
    display: block;
  }
  .chat-section {
    width: 90%;
    margin: auto;
  }
  .chat-center-area {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .chat-right-area {
    display: none;
  }
  .chat-right-area.active {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }
  .chat-left-area {
    display: block !important;
  }

  .chat-left-area {
    min-width: 300px;
  }
  .chat-center-area {
    max-width: calc(100% - 300px);
  }
  .chat-section {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .payment-cointainer {
    width: 100%;
    margin: 1rem;
  }
}

@media (max-width: 1400px) {
  /* html {
    overflow: hidden;
  } */
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 5px solid #f0f0f0;
  }
  .chat-page-section .container-fluid {
    padding: 0;
    margin: 0;
  }
  .chat-page-section .container-fluid > .row {
    margin: 0;
  }

  .chat-section {
    width: 100%;
    margin: 0;
  }
  .chat-box-display {
    overflow: hidden;
  }
  .chat-section {
    width: calc(100% - 1rem);
    margin: auto;
  }

  .chat-section {
    width: 100%;
    margin: 0;
  }
  .chat-box-display {
    overflow: hidden;
  }
  .chat-remarks-icon {
    display: none;
  }

  .chat-box-display .chat-menu-border {
    background: #f0f2f5;
    border-radius: 0;
    border-bottom: 2px solid #eaeaea;
  }
  .chat-msg-area {
    margin-left: 3rem;
    width: calc(100% - 6.5rem);
  }
  .chat-page-msg-section {
    padding: 0.5rem 0;
    border-radius: 0;
  }
  .chat-remarks-box {
    margin: 30px;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
  }
  .chat-page-body {
    background: #fbfbfb;
    height: calc(100% - 168px);
  }
  .chat-search-input {
    border: 0;
    background: #f0f2f5;
  }
}
@media (min-width: 1600px) {
  .chat-section {
    margin: auto;
    width: 80%;
    height: 100%;
    align-items: center;
  }
  .chat-remarks-icon {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 1599.98px) {
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 5px solid #f0f0f0;
  }
  .chat-page-section .container-fluid {
    padding: 0;
    margin: 0;
  }
  .chat-page-section .container-fluid > .row {
    margin: 0;
  }
  .chat-box-wrapper {
    background: #fff;
    height: 100%;
    padding: 0;
  }
  .chat-section {
    width: 100%;
    margin: 0;
  }
  .chat-box-display {
    overflow: hidden;
  }
  .chat-box-display .chat-menu-border {
    background: #f0f2f5;
    border-radius: 0;
    border-bottom: 2px solid #eaeaea;
  }
  .chat-msg-area {
    margin-left: 3rem;
    width: calc(100% - 6.5rem);
  }
  .chat-page-msg-section {
    padding: 0.5rem 0;
    border-radius: 0;
  }
  .chat-remarks-box {
    margin: 30px;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
  }
  .chat-page-body {
    background: #fbfbfb;
    height: calc(100% - 128px);
  }
  .chat-search-input {
    border: 0;
    background: #f0f2f5;
  }
  .chat-box {
    height: calc(100vh - 40px);
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .chat-left-area {
    min-width: 300px;
  }
  .chat-remarks-icon {
    display: block !important;
  }

  .tab-chat-section .chat-left-area {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tab-chat-section .chat-right-area {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tab-chat-section .chat-left-area.tab-chat-left {
    display: none;
  }

  .tab-chat-section .chat-left-area.tab-chat-left.tab-chat-left-hidden {
    display: none !important;
  }

  .tab-chat-section .chat-center-area.tab-chat-center.tab-chat-center-hidden {
    display: none !important;
  }
  .chat-center-area {
    max-width: calc(100% - 300px);
  }
  .tab-chat-section .tab-chat-center {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .chat-section {
    width: 100%;
  }
  .tab-chat-section .chat-box-profile-svg-back {
    display: inline-block;
  }

  .tab-chat-section .chat-tab-remarks-icon {
    display: inline-block !important;
  }

  .chat-right-area.active {
    max-width: calc(100% - 300px);
  }
  .tab-chat-section .chat-right-area.active {
    max-width: 100%;
  }
}

.chat-sent-text {
  padding: 0.25rem 0.5rem;
  background: #dce8da;
  border-radius: 5px;
  display: inline-block;
  text-align: left;
}

.chat-remarks-username {
  font-weight: 700;
}
.chat-box-profile-section {
  min-width: 300px;
}

.chat-user {
  white-space: nowrap;
}
