.icons-color {
  color: white;
}
.video-container {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.video-container-1 {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.video-container.fullscreen {
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
}
.video-container.fullscreen .control--fullscreen .fa-expand {
  display: none;
}
.video-container.fullscreen .control--fullscreen .fa-compress {
  display: inline-flex;
}
.video-container__video {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
}
.video-container__video::-webkit-media-controls {
  display: none !important;
}
.video-container__controls {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  height: 80px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0.35) 60%,
    transparent
  );
  opacity: 1;
  transition: all 150ms ease;
}

/* .video-container:hover .video-container__controls {
    opacity: 1;
  } */

.video-container__controls.visible {
  opacity: 1;
}
.video-container__controls .video--progress {
  width: calc(100% - 20px);
  cursor: pointer;
  height: 24px;
  position: absolute;
  left: 0;
  top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.video-container__controls .video--progress:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.35);
}
.video-container__controls .progress__current {
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  background: #ff0000;
  z-index: 2;
  transform: translateY(-50%);
}
.video-container__controls .progress__current:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  border-radius: 50%;
  border: 4px solid white;
  width: 6px;
  height: 6px;
  background: #ff0000;
}
.video-container__controls .video--progress .tooltip {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
}
.video-container__controls .video--progress .tooltip video {
  width: 100%;
}
.video-container__controls .control {
  border: 0;
  padding: 0;
  background: transparent;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container__controls .control:hover i {
  color: #ff0000;
  color: #fff;
}
.video-container__controls .control i {
  font-size: 16px;
  color: white;
}
.video-container__controls .control--play .fa-pause {
  display: inline-flex;
}
.video-container__controls .control--play .fa-play {
  display: none;
}
.video-container__controls .control--play.paused .fa-play {
  display: inline-flex;
}
.video-container__controls .control--play.paused .fa-pause {
  display: none;
}
.video-container__controls .control--volume {
  width: unset;
}
.video-container__controls .control--volume__button {
  width: 20px;
  display: flex;
  justify-content: flex-start;
}
.video-container__controls .control--volume__button .fa-volume-up {
  display: inline-flex;
}
.video-container__controls .control--volume__button .fa-volume-off {
  display: none;
}
.video-container__controls .control--volume__slider {
  display: flex;
  opacity: 0;
  width: 90px;
  margin-left: 10px;
  height: 0px;
  -webkit-appearance: none;
}
.video-container__controls
  .control--volume__slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  background: #fff;
}
.video-container__controls .control--volume__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -5px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #ff0000;
  background-color: #fff;
  cursor: pointer;
}

.video-container__controls .control--volume__slider::-moz-range-track {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  background: #fff;
}
.video-container__controls .control--volume__slider::-moz-range-thumb {
  -webkit-appearance: none;
  margin-top: -5px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #ff0000;
  background-color: #fff;
  cursor: pointer;
}

.video-container__controls .control--volume__slider:focus {
  outline: none;
}
.video-container__controls .control--volume__slider:hover {
  opacity: 1;
}
.video-container__controls .control--volume:hover .control--volume__slider {
  opacity: 1;
}
.video-container__controls .control--volume.muted .fa-volume-up {
  display: none;
}
.video-container__controls .control--volume.muted .fa-volume-off {
  display: inline-flex;
}
.video-container__controls .control--quality {
  margin-left: auto;
  margin-right: 10px;
}
.video-container__controls .control--fullscreen .fa-compress {
  display: none;
}
.video-container.normalsize {
  height: 350px;
}
.video-quality-section {
  position: absolute;
  width: 13rem;
  background: rgb(0 0 0 / 70%);
  right: 1rem;
  bottom: -100%;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  color: #fff;
  transition: 0.1s all;
  display: block;
  bottom: 4rem;
}
.video-quality-section.active {
  display: block;
  bottom: 4rem;
}
.quality-list {
  height: 2.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  cursor: pointer;
}
.quality-list:hover {
  background: rgb(255 255 255 / 10%);
}
.quality-list sup {
  font-size: 0.8rem;
  color: #9e9e9e;
  margin: -11px 0 0 10px;
  font-weight: 700;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}
@media (max-width: 767.98px) {
  .video-container__controls {
    opacity: 1;
    height: 100px;
  }
  .video-container__controls .control {
    width: 45px;
    justify-content: unset;
  }
  .video-container__controls .control i {
    font-size: 30px;
  }
  .video-container__controls .control--volume__slider {
    margin-left: 35px;
    opacity: 1;
    width: 150px;
  }
  .video-container__controls
    .control--volume__slider::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 25px;
  }
  .video-container__controls .control--volume__slider::-webkit-slider-thumb {
    margin-top: -10px;
    height: 30px;
    width: 30px;
  }
  .video-container__controls .progress__current,
  .video-container__controls .video--progress:before {
    height: 10px;
  }
  .video-container__controls .progress__current:before {
    width: 22px;
    height: 22px;
  }
}
