html {
  scroll-snap-type: y mandatory;
}

.video-card-wrapper::-webkit-scrollbar {
  display: none;
}
.video-card-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.video-card-wrapper {
  background-color: black;
  position: relative;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: calc(100vh - 50px - 46px);
}

.video-card {
  height: 100%;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.sharurl {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  width: 70%;
  margin: auto;
  border: 2px solid yellowgreen;
}

.sharurl .url {
  display: block;
  margin-bottom: 10px;
}

.sharurl button {
  padding: 6px 6px;
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(253, 254, 255);
}
.sharurl button:hover {
  background-color: rgb(231, 226, 226);
}

.sharurl .close-btn {
  position: absolute;
  right: 10px;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.video-player {
  width: 100%;
}
.player-wrapper {
  position: relative;
}
.thumbs-wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.thumb-parent {
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.image-thumb-wrapper {
  height: 100%;
  text-decoration: none !important;
  color: #fff;
}

.image-thb {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 0.2rem;
}

.btn-sub {
  border-radius: 25px;
  padding: 0;
  color: #fff;
  border: 1px solid #fff;
  background: none;
  font-size: 1rem;
  width: 95px;
}

.thumb-icons-flex {
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.thumb-icons {
  display: flex;
  justify-content: end;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.thumb-icons-items {
  color: white;
  display: flex;
  justify-content: center;
}
.thumb-reel-description-wrapper {
  position: absolute;
  bottom: 70px;
  color: white;
  width: 100%;
  padding-left: 0.3rem;
  padding-right: 3.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto; /* Ensure scrolling is enabled */
}

.thumb-reel-description-wrapper-hieght-set {
  height: 60%; /* Ensure this is appropriate for the parent element's height */
  min-height: 150px; /* Set a minimum height to avoid collapsing */
}

.thumb-reel-description {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.thumb-reel-description-read-more {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 0.3rem;
}

.thumb-reel-description-read-more-scroll {
  padding-left: 0.3rem;
  height: 100%;
  overflow: auto;
  /*scrollbar-width: none;  Hides scrollbar in Firefox */
  /*  -ms-overflow-style: none; Hides scrollbar in IE */
  /* scrollbar-width: none; */
}

/* Webkit-based browsers */
/* .thumb-reel-description-read-more-scroll::-webkit-scrollbar {
  display: none !important; 
} */

.thumb-title-card {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.thumb-title-card-read-more {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-weight: 700;
}

.title-card {
  color: "#fff";
  margin-left: 5px;
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.thumb-desc {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 2px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  overflow: hidden;
}

.speakmute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
