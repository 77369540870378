.carousel-section {
  display: flex;
  width: 100%;
  position: relative;
  padding: 0 1.5rem 0rem 1rem;
  overflow: hidden;
}

.course-title-wrapper {
  padding: 1rem 1.5rem 1rem 1rem;
  margin-left: 7px;
  margin-right: 7px;
}

.carousel-section .carousel-nav:hover {
  background: #6e6f70;
}
.carousel-section .carousel-nav:first-child {
  display: block;
}

.carousel-section .carousel {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.course-img-area {
  width: 100%;
}
.course-img-area img {
  width: 100%;
}
.course-desc {
  padding: 0.5rem;
  padding-top: 0.2rem;
}

.carouse-detail-title {
  font-weight: 500;
  font-size: 1.2rem;
}
.carouse-detail-desc {
  margin: 0.25rem 0;
}
.carouse-detail-list {
  margin-bottom: 0.25rem;
  display: flex;
}
.carouse-detail-list-img {
  height: 16px;
  margin: 0.25rem 0.5rem 0 0;
}
.carouse-button-area {
  display: flex;
  margin-top: 80%;
}
.btn-carouse-addtocart {
  width: calc(100% - 4rem);
  height: 3rem;
  border: 0;
  border-radius: 0;
}
.btn-carouse-faviourate {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-color: #000;
}
.faviourate-icon {
  width: 100%;
}
.carouse-popup {
  display: none;
  position: absolute;
  top: 0;
  background: #fff;
  height: 370px;
  width: 300px;
  left: 100%;
  z-index: 1;
  padding: 1rem;
  box-shadow: 0 0 0 1px #d1d7dc, 0 2px 4px rgba(0, 0, 0, 0.08),
    0 4px 12px rgba(0, 0, 0, 0.08);
  font-size: 0.8rem;
  font-weight: 500;
}
.carouse-popup:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  height: 10px;
  width: 10px;
  background: #fff;
  border: 1px solid transparent;
  border-top-color: #d1d7dc;
  border-left-color: #d1d7dc;
  transform: rotate(-45deg);
}
.carouse-detail-desc {
  margin: 0.25rem 0;
}
.box:hover .carouse-popup {
  display: block;
}
.carousel.dragging {
  cursor: grab;
  scroll-behavior: auto;
}
.carousel.dragging .box {
  pointer-events: none;
}
.carousel .box {
  height: 380px;
  object-fit: cover;
  user-select: none;
  width: calc(100% / 5);
  display: inline-block;
  white-space: normal;
  border: 7px solid #fff;
  position: relative;
}
.carousel i.boxmg:first-child {
  margin-left: 0px;
}
.carousel-nav {
  position: absolute;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background: #343536;
  border: 1px solid rgb(255 255 255 / 80%);
  z-index: 1;
  top: 20%;
}
.carousel-prev {
  transform: rotate(-135deg);
  left: 0;
}
.carousel-next {
  transform: rotate(45deg);
  right: 0;
}
.carousel-nav:before {
  content: "";
}
.carousel-prev:before,
.carousel-next:before {
  content: "";
  border-right: 2px solid white;
  border-top: 2px solid white;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 18px;
  left: 14px;
}

.carousel-title {
  width: 100%;
}

.broder-top {
  border-top: 1px solid #818582;
}

.broder-bottom {
  border-bottom: 1px solid #818582;
}

.cart-icons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.cart-icon {
  text-align: center;
}

.cart-texts {
  font-size: 11px;
  margin-left: 2px;
  margin-right: 4px;
}

@media (min-width: 0px) and (max-width: 575.98px) {
  .carousel .box {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel .box {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel .box {
    width: calc(100% / 3);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel .box {
    width: calc(100% / 4);
  }
}
