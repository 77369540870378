.input_learn_text_field {
  outline: none;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid;
  background-color: white;
}

.learn_line_div {
  line-height: 28px;
}

.learn_line {
  float: left;
  width: 85%;
}

.checked_area {
  float: left;
  width: 5%;
  text-align: center;
  line-height: 28px;
}
