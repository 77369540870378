.loader-spiner-addtionl {
  width: 20px !important;
  height: 20px !important;
  border-bottom-color: #16d984 !important ;
  margin-left: 5px;
}

.btn-text-cenetr {
  display: flex;
  justify-content: center;
  align-items: center;
}
