body {
  font-family: "Open Sans", sans-serif;
  line-height: 22px;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

nav li a:hover {
  color: yellow;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
}

.modal-overlay-loginpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
  visibility: hidden;
}
/* OPEN/CLOSE MODAL */
.show-modal {
  visibility: visible !important;
  z-index: 10;
}
.modal-container {
  background: white;
  border-radius: 0.25rem;
  width: 400px;
  max-width: 620px;
  position: relative;
}

.modal-container-loginpage {
  border: 1px solid gray;
}
.close-modal-btn {
  position: absolute;
  top: -5px;
  right: 1rem;
  font-size: 27px;
  background: transparent;
  border-color: transparent;
  color: #afafaf;
  cursor: pointer;
}

.close-modal-btn:hover {
  color: #000;
  cursor: pointer;
}

.model-body {
  font-family: "Open Sans", sans-serif;
  margin: 20px;
  margin-top: 60px;
  font-size: 14px;
}

.form-group-navbar {
  border-bottom: 1px solid #eeeeee;
  margin: 20px;
  margin-top: 30px;
}

.input-model-style {
  font-size: 14px;
  line-height: 1.428571429;
  border: none;
  margin-left: 10px;
  color: black;
}

.input-login {
  width: 90%;
  outline: none;
}

.model-font-color {
  color: #afafaf;
}

.model-forgot-password {
  color: #8a8a8a;
  margin-left: 20px;
  cursor: pointer;
}

.btn-container {
  text-align: center;
  margin-top: 30px;
}

.login-model-header {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #3b3b3b;
  margin-top: 5px;
}

.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: none;
  background-color: #eee;
}

.sign-in-hover:hover {
  background-color: #bef1fa;
}

.searchSuggestions {
  width: 39.4%;
  margin-top: 5px;
  position: fixed;
  padding: "10px";
  background: #fff;
  border: 1px solid #ccc;
  border-top-color: rgb(204, 204, 204);
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #d9d9d9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.searchSuggestionsLists {
  padding: 5px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 450;
}
.searchSuggestionsLists:hover {
  background-color: #eee;
}

.search-close {
  color: #8c8c8c;
  cursor: pointer;
  margin: -3px 0 0 -1px;
  border: 1px solid #d9d9d9;
  height: 40px;
  width: 40px;
}

.reel-menu-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 0px;
  align-items: center;
}

.reel-navbar-drop-content {
  background-color: transparent !important;
  border-top: none !important;
  margin-left: 5px !important;
}

.footer-menu-bottom {
  background-color: white;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.footer-menu-reel-bottom {
  background-color: black !important;
  height: 50px;
  display: flex;
  justify-content: space-around;
  color: white !important;
}

@media only screen and (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
}

@media only screen and (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
}

@media only screen and (max-width: 767px) {
  .dark-fix-nav {
    position: fixed;
    background-color: transparent;
  }
}

@media (max-width: 575.98px) {
  .navbar-header {
    width: calc(100% - 170px);
  }
  .navbar-right {
    width: 25% !important;
    max-width: 85px !important;
    white-space: nowrap;
    flex-direction: unset;
    justify-content: right;
  }
  .sign-in-hover.nav-item {
    padding: 15px 0px !important;
  }
  .modal-container {
    width: 90%;
  }
  .login-model-header {
    margin-top: 1rem;
  }
  .close-modal-btn {
    top: 0.75rem;
    right: 0.5rem;
  }
}
