.ui-tabs-title {
  border-bottom: 1px solid #eee;
  margin: 0px 15px;
  padding: 0px 5px 0px 1x;
}

.edit-content-widget {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.4);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  padding: 12px 8px;
  background-color: white;
  margin-bottom: 10px;
}

.tab-inner-content-padding {
  display: block;
  border-width: 0;
  padding: 1.5em 1px;
  background: none;
  margin: 0px 15px;
}
.tab-profile-image {
  width: 200px;
  height: 200px;
  border-radius: 100% !important;
}

.briefCv-border {
  border: 1px solid #d1d1d1;
  margin-bottom: 10px;
}

.briefCv-border-bottom {
  border-bottom: 1px solid #d1d1d1;
}

.edit-bio-sub-menu {
  display: inline-block;
  line-height: 25px;
  position: relative;
  left: 2%;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  padding: 5px;
}
